var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[(_vm.notification.message)?_c('b-alert',{staticClass:"mt-1",attrs:{"variant":_vm.notification.type,"show":"","dismissible":""},on:{"dismissed":function($event){return _vm.$store.dispatch('notification/clear')}}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('div',{staticClass:"card"},[_c('b-overlay',{attrs:{"show":_vm.fetchingProfile,"rounded":"md","opacity":"0.5"}},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Affiliate Profile Update")]),_c('p',{staticClass:"card-title-desc"},[_vm._v(" Fill the form below to update your profile. Make sure to use an accurate account information. ")]),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.updateProfile.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip01"}},[_vm._v("First name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile.firstname),expression:"profile.firstname"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitform && _vm.$v.profile.firstname.$error,
                      },attrs:{"id":"validationTooltip01","type":"text","placeholder":"First name","value":"Mark"},domProps:{"value":(_vm.profile.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.profile, "firstname", $event.target.value)}}}),(_vm.submitform && _vm.$v.profile.firstname.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.profile.firstname.required)?_c('span',[_vm._v("Please provide valid First name.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip02"}},[_vm._v("Middle name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile.middlename),expression:"profile.middlename"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitform && _vm.$v.profile.middlename.$error,
                      },attrs:{"id":"validationTooltip02","type":"text","placeholder":"Middle name","value":"Otto"},domProps:{"value":(_vm.profile.middlename)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.profile, "middlename", $event.target.value)}}}),(_vm.submitform && _vm.$v.profile.middlename.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.profile.middlename.required)?_c('span',[_vm._v("Please provide valid middle name.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip02"}},[_vm._v("Last name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile.lastname),expression:"profile.lastname"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitform && _vm.$v.profile.lastname.$error,
                      },attrs:{"id":"validationTooltip02","type":"text","placeholder":"Last name","value":"Otto"},domProps:{"value":(_vm.profile.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.profile, "lastname", $event.target.value)}}}),(_vm.submitform && _vm.$v.profile.lastname.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.profile.lastname.required)?_c('span',[_vm._v("Please provide valid Last name.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltipUsername"}},[_vm._v("Username")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile.username),expression:"profile.username"}],staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.profile.username.$error,
                        },attrs:{"id":"validationTooltipUsername","type":"text","placeholder":"Username","readonly":"","aria-describedby":"validationTooltipUsernamePrepend"},domProps:{"value":(_vm.profile.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.profile, "username", $event.target.value)}}}),(_vm.submitform && _vm.$v.profile.username.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.profile.username.required)?_c('span',[_vm._v("Please choose a unique and valid username.")]):_vm._e()]):_vm._e()])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip04"}},[_vm._v("State")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile.state),expression:"profile.state"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.profile.state.$error,
                      },attrs:{"id":"validationTooltip04","type":"text","placeholder":"State"},domProps:{"value":(_vm.profile.state)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.profile, "state", $event.target.value)}}}),(_vm.submitform && _vm.$v.profile.state.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.profile.state.required)?_c('span',[_vm._v("Please provide a valid state.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip05"}},[_vm._v("Country")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile.country),expression:"profile.country"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.profile.country.$error,
                      },attrs:{"id":"validationTooltip05","type":"text","placeholder":"Country"},domProps:{"value":(_vm.profile.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.profile, "country", $event.target.value)}}}),(_vm.submitform && _vm.$v.profile.country.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.profile.country.required)?_c('span',[_vm._v("Please provide a valid Country")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip06"}},[_vm._v("Phone Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile.phone),expression:"profile.phone"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.profile.phone.$error,
                      },attrs:{"id":"validationTooltip06","type":"text","placeholder":"Phone Number","value":"Otto"},domProps:{"value":(_vm.profile.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.profile, "phone", $event.target.value)}}}),(_vm.submitform && _vm.$v.profile.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.profile.phone.required)?_c('span',[_vm._v("Please provide valid Phone Number")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip04"}},[_vm._v("Account Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile.account_name),expression:"profile.account_name"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitform && _vm.$v.profile.account_name.$error,
                      },attrs:{"id":"validationTooltip07","type":"text","placeholder":"Account Name"},domProps:{"value":(_vm.profile.account_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.profile, "account_name", $event.target.value)}}}),(_vm.submitform && _vm.$v.profile.account_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.profile.account_name.required)?_c('span',[_vm._v("Please provide a valid Account Name.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip05"}},[_vm._v("Account No")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile.account_number),expression:"profile.account_number"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitform && _vm.$v.profile.account_number.$error,
                      },attrs:{"id":"validationTooltip05","type":"number","placeholder":"Account No."},domProps:{"value":(_vm.profile.account_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.profile, "account_number", $event.target.value)}}}),(_vm.submitform && _vm.$v.profile.account_number.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.profile.account_number.required)?_c('span',[_vm._v("Please provide a valid Account No")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip06"}},[_vm._v("Bank Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profile.bank_name),expression:"profile.bank_name"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitform && _vm.$v.profile.bank_name.$error,
                      },attrs:{"id":"validationTooltip06","type":"text","placeholder":"Bank Name","value":"Otto"},domProps:{"value":(_vm.profile.bank_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.profile, "bank_name", $event.target.value)}}}),(_vm.submitform && _vm.$v.profile.bank_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.profile.bank_name.required)?_c('span',[_vm._v("Please provide valid Bank Name")]):_vm._e()]):_vm._e()])])]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(" Update Profile "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"align-middle",attrs:{"small":""}})],1)])])])],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Affiliate Password Change")]),_c('p',{staticClass:"card-title-desc"},[_vm._v(" Fill the form below to update your password. ")]),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip9"}},[_vm._v("New Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordForm.password),expression:"passwordForm.password"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.submitPasswordForm && _vm.$v.passwordForm.password.$error,
                    },attrs:{"id":"validationTooltip09","type":"password","placeholder":"New Password","value":"Mark"},domProps:{"value":(_vm.passwordForm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.passwordForm, "password", $event.target.value)}}}),(
                      _vm.submitPasswordForm && _vm.$v.passwordForm.password.$error
                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.passwordForm.password.required)?_c('span',[_vm._v("Please enter new password.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip9"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordForm.confirmpass),expression:"passwordForm.confirmpass"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.submitPasswordForm &&
                        _vm.$v.passwordForm.confirmpass.$error,
                    },attrs:{"id":"validationTooltip09","type":"password","placeholder":"Confirm Password","value":"Mark"},domProps:{"value":(_vm.passwordForm.confirmpass)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.passwordForm, "confirmpass", $event.target.value)}}}),(
                      _vm.submitPasswordForm && _vm.$v.passwordForm.confirmpass.$error
                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.passwordForm.confirmpass.required)?_c('span',[_vm._v("Please enter password confirmation.  ")]):_vm._e(),(!_vm.$v.passwordForm.confirmpass.sameAsPassword)?_c('span',[_vm._v("Password does not match.")]):_vm._e()]):_vm._e()])])]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(" Change Password "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingPassword),expression:"loadingPassword"}],staticClass:"align-middle",attrs:{"small":""}})],1)])])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Affiliate Profile Picture")]),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.changePhoto.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('img',{staticStyle:{"width":"170px","height":"170px","object-fit":"cover"},attrs:{"src":_vm.profilePhoto,"alt":"Profile Picture"}})]),_c('div',{staticClass:"col-md-5 my-auto"},[_c('div',{staticClass:"form-group position-relative"},[_c('label',{attrs:{"for":"validationTooltip9"}},[_vm._v("Change Profile Picture")]),_c('input',{ref:"photo",staticClass:"form-control",class:{
                      'is-invalid':
                        (_vm.submitPhotoForm && _vm.$v.photoForm.photo.$error) ||
                        _vm.errors.photo,
                    },attrs:{"id":"validationTooltip09","type":"file","accept":"image/*"},on:{"change":_vm.attachPhoto}}),(
                      (_vm.submitPhotoForm && _vm.$v.photoForm.photo.$error) ||
                      _vm.errors.photo
                    )?_c('div',{staticClass:"invalid-feedback"},[(_vm.errors.photo)?_c('span',[_vm._v(_vm._s(_vm.errors.photo[0])+".  ")]):_vm._e(),(!_vm.$v.photoForm.photo.required)?_c('span',[_vm._v("This field is required")]):_vm._e()]):_vm._e()]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(" Change Photo "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingPhoto),expression:"loadingPhoto"}],staticClass:"align-middle",attrs:{"small":""}})],1)])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }