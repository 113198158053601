<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { userService } from "@/services/user.service";

import {
  required,
  // email,
  // minLength,
  sameAs,
  // maxLength,
  // minValue,
  // maxValue,
  // numeric,
  // url,
  // alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "CadLands Affiliate Profile",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Profile",
      form: {
        firstname: "",
        middlename: "",
        lastname: "",
        city: "",
        state: "",
        zipcode: "",
      },
      profile: {
        firstname: "",
        middlename: "",
        lastname: "",
        username: "",
        city: "",
        state: "",
      },
      passwordForm: {
        password: "",
        confirmpass: "",
      },
      photoForm: {
        photo: null,
      },
      submitted: false,
      submitform: false,
      submitPasswordForm: false,
      submit: false,
      typesubmit: false,
      loading: false,
      loadingPassword: false,
      errors: {},
      fetchingProfile: false,
      loadingPhoto: false,
      submitPhotoForm: false,
      editPhotoUrl: null,
    };
  },
  validations: {
    profile: {
      firstname: { required },
      middlename: { required },
      lastname: { required },
      username: { required },
      country: { required },
      state: { required },
      phone: { required },
      account_name: { required },
      account_number: { required },
      bank_name: { required },
    },
    passwordForm: {
      password: { required },
      confirmpass: { required, sameAsPassword: sameAs("password") },
    },
    photoForm: {
      photo: { required },
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    authUser() {
      return this.$store.state.auth.user;
    },
    profilePhoto() {
      return this.editPhotoUrl ?? this.$store.state.auth.user.photo ?? '/img/avatar-2.43fa192e.jpg'
    }
  },
  mounted: async function () {
    await this.setProfileData();
  },
  methods: {
    async setProfileData() {
      try {
        this.fetchingProfile = true;
        const response = await userService.getProfile();
        this.profile = response.data.data.user;
        this.fetchingProfile = false;
      } catch (error) {
        return;
      }
    },
    async updateProfile() {
      this.submitform = true;
      this.$v.$touch();

      if (this.$v.profile.$invalid) {
        return;
      } else {
        try {
          this.errors = {};
          this.loading = true;
          var response = await userService.updateProfile(this.profile);

          console.log(response);
          this.$store.dispatch("notification/success", response.data.message);
          this.$store.dispatch("auth/setUser", response.data.data);
          // this.profile = response.data.data
          this.loading = false;
          this.$bvToast.toast(response.data.message, {
            title: "Successful!",
            variant: "success",
            solid: true,
          });
        } catch (error) {
          this.loading = false;
          console.log(error);
          switch (error.response.status) {
            case 422:
              console.log(error.response.data.errors);
              this.errors = error.response.data.errors;
              break;
            case 500:
              this.$bvToast.toast(error.response.data.message, {
                title: "Error!",
                variant: "danger",
                solid: true,
              });
              break;
            default:
              this.$bvToast.toast("Something went wrong. Please try again!", {
                title: "Error!",
                variant: "danger",
                solid: true,
              });
              break;
          }
        }
      }
    },

    async changePassword() {
      this.submitPasswordForm = true;
      this.$v.$touch();

      if (this.$v.passwordForm.$invalid) {
        return;
      } else {
        try {
          this.errors = {};
          this.loadingPassword = true;
          var response = await userService.updateProfile(this.passwordForm);

          this.$store.dispatch("notification/success", response.data.message);
          this.loadingPassword = false;
          this.submitPasswordForm = false;

          this.passwordForm.password = "";
          this.passwordForm.confirmpass = "";
          this.$bvToast.toast(response.data.message, {
            title: "Successful!",
            variant: "success",
            solid: true,
          });
        } catch (error) {
          this.loadingPassword = false;
          console.log(error);
          switch (error.response.status) {
            case 422:
              console.log(error.response.data.errors);
              this.errors = error.response.data.errors;
              break;
            case 500:
              this.$bvToast.toast(error.response.data.message, {
                title: "Error!",
                variant: "danger",
                solid: true,
              });
              break;
            default:
              this.$bvToast.toast("Something went wrong. Please try again!", {
                title: "Error!",
                variant: "danger",
                solid: true,
              });
              break;
          }
        }
      }
    },
    attachPhoto() {
      this.photoForm.photo = this.$refs.photo.files[0];
      this.editPhotoUrl = URL.createObjectURL(this.photoForm.photo);
    },
    async changePhoto() {
      this.submitPhotoForm = true;
      this.$v.$touch();

      if (this.$v.photoForm.$invalid) {
        return;
      } else {
        try {
          this.errors = {};
          this.loadingPhoto = true;

          const formData = new FormData();
          formData.append("photo", this.photoForm.photo);
          var response = await userService.changePhoto(formData);
          // update profile picture in store
          this.$store.dispatch("auth/updatePhoto", response.data.data)
          this.loadingPhoto = false;
          this.$bvToast.toast('response.data.message', {
            title: "Successful!",
            variant: "success",
            solid: true,
          });
        } catch (error) {
          this.loadingPhoto = false;
          console.log(error);
          if (error.response)
            switch (error.response.status) {
              case 422:
                console.log(error.response.data.errors);
                this.errors = error.response.data.errors;
                break;
              case 500:
                this.$bvToast.toast(error.response.data.message, {
                  title: "Error!",
                  variant: "danger",
                  solid: true,
                });
                break;
              default:
                this.$bvToast.toast("Something went wrong. Please try again!", {
                  title: "Error!",
                  variant: "danger",
                  solid: true,
                });
                break;
            }
        }
      }
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <!-- end col -->
      <div class="col-lg-12">
        <b-alert
          :variant="notification.type"
          class="mt-1"
          v-if="notification.message"
          show
          dismissible
          @dismissed="$store.dispatch('notification/clear')"
          >{{ notification.message }}</b-alert
        >
        <div class="card">
          <b-overlay :show="fetchingProfile" rounded="md" opacity="0.5">
            <div class="card-body">
              <h4 class="card-title">Affiliate Profile Update</h4>
              <p class="card-title-desc">
                Fill the form below to update your profile. Make sure to use an
                accurate account information.
              </p>
              <form class="needs-validation" @submit.prevent="updateProfile">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group position-relative">
                      <label for="validationTooltip01">First name</label>
                      <input
                        id="validationTooltip01"
                        v-model="profile.firstname"
                        type="text"
                        class="form-control"
                        placeholder="First name"
                        value="Mark"
                        :class="{
                          'is-invalid':
                            submitform && $v.profile.firstname.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.profile.firstname.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.profile.firstname.required"
                          >Please provide valid First name.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group position-relative">
                      <label for="validationTooltip02">Middle name</label>
                      <input
                        id="validationTooltip02"
                        v-model="profile.middlename"
                        type="text"
                        class="form-control"
                        placeholder="Middle name"
                        value="Otto"
                        :class="{
                          'is-invalid':
                            submitform && $v.profile.middlename.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.profile.middlename.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.profile.middlename.required"
                          >Please provide valid middle name.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group position-relative">
                      <label for="validationTooltip02">Last name</label>
                      <input
                        id="validationTooltip02"
                        v-model="profile.lastname"
                        type="text"
                        class="form-control"
                        placeholder="Last name"
                        value="Otto"
                        :class="{
                          'is-invalid':
                            submitform && $v.profile.lastname.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.profile.lastname.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.profile.lastname.required"
                          >Please provide valid Last name.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group position-relative">
                      <label for="validationTooltipUsername">Username</label>
                      <div class="input-group">
                        <!-- <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text">@</span>
                      </div> -->
                        <input
                          id="validationTooltipUsername"
                          v-model="profile.username"
                          type="text"
                          class="form-control"
                          placeholder="Username"
                          readonly
                          aria-describedby="validationTooltipUsernamePrepend"
                          :class="{
                            'is-invalid':
                              submitform && $v.profile.username.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.profile.username.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.profile.username.required"
                            >Please choose a unique and valid username.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group position-relative">
                      <label for="validationTooltip04">State</label>
                      <input
                        id="validationTooltip04"
                        v-model="profile.state"
                        type="text"
                        class="form-control"
                        placeholder="State"
                        :class="{
                          'is-invalid': submitform && $v.profile.state.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.profile.state.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.profile.state.required"
                          >Please provide a valid state.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group position-relative">
                      <label for="validationTooltip05">Country</label>
                      <input
                        id="validationTooltip05"
                        v-model="profile.country"
                        type="text"
                        class="form-control"
                        placeholder="Country"
                        :class="{
                          'is-invalid': submitform && $v.profile.country.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.profile.country.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.profile.country.required"
                          >Please provide a valid Country</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group position-relative">
                      <label for="validationTooltip06">Phone Number</label>
                      <input
                        id="validationTooltip06"
                        v-model="profile.phone"
                        type="text"
                        class="form-control"
                        placeholder="Phone Number"
                        value="Otto"
                        :class="{
                          'is-invalid': submitform && $v.profile.phone.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.profile.phone.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.profile.phone.required"
                          >Please provide valid Phone Number</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group position-relative">
                      <label for="validationTooltip04">Account Name</label>
                      <input
                        id="validationTooltip07"
                        v-model="profile.account_name"
                        type="text"
                        class="form-control"
                        placeholder="Account Name"
                        :class="{
                          'is-invalid':
                            submitform && $v.profile.account_name.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.profile.account_name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.profile.account_name.required"
                          >Please provide a valid Account Name.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group position-relative">
                      <label for="validationTooltip05">Account No</label>
                      <input
                        id="validationTooltip05"
                        v-model="profile.account_number"
                        type="number"
                        class="form-control"
                        placeholder="Account No."
                        :class="{
                          'is-invalid':
                            submitform && $v.profile.account_number.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.profile.account_number.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.profile.account_number.required"
                          >Please provide a valid Account No</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group position-relative">
                      <label for="validationTooltip06">Bank Name</label>
                      <input
                        id="validationTooltip06"
                        v-model="profile.bank_name"
                        type="text"
                        class="form-control"
                        placeholder="Bank Name"
                        value="Otto"
                        :class="{
                          'is-invalid':
                            submitform && $v.profile.bank_name.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.profile.bank_name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.profile.bank_name.required"
                          >Please provide valid Bank Name</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <button class="btn btn-primary" type="submit">
                  Update Profile
                  <b-spinner
                    v-show="loading"
                    small
                    class="align-middle"
                  ></b-spinner>
                </button>
              </form>
            </div>
          </b-overlay>
        </div>
        <!-- end card -->
      </div>

      <!-- end col -->
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Affiliate Password Change</h4>
            <p class="card-title-desc">
              Fill the form below to update your password.
            </p>
            <form class="needs-validation" @submit.prevent="changePassword">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group position-relative">
                    <label for="validationTooltip9">New Password</label>
                    <input
                      id="validationTooltip09"
                      v-model="passwordForm.password"
                      type="password"
                      class="form-control"
                      placeholder="New Password"
                      value="Mark"
                      :class="{
                        'is-invalid':
                          submitPasswordForm && $v.passwordForm.password.$error,
                      }"
                    />
                    <div
                      v-if="
                        submitPasswordForm && $v.passwordForm.password.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.passwordForm.password.required"
                        >Please enter new password.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group position-relative">
                    <label for="validationTooltip9">Confirm Password</label>
                    <input
                      id="validationTooltip09"
                      v-model="passwordForm.confirmpass"
                      type="password"
                      class="form-control"
                      placeholder="Confirm Password"
                      value="Mark"
                      :class="{
                        'is-invalid':
                          submitPasswordForm &&
                          $v.passwordForm.confirmpass.$error,
                      }"
                    />
                    <div
                      v-if="
                        submitPasswordForm && $v.passwordForm.confirmpass.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.passwordForm.confirmpass.required"
                        >Please enter password confirmation. &nbsp;</span
                      >
                      <span v-if="!$v.passwordForm.confirmpass.sameAsPassword"
                        >Password does not match.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" type="submit">
                Change Password
                <b-spinner
                  v-show="loadingPassword"
                  small
                  class="align-middle"
                ></b-spinner>
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Affiliate Profile Picture</h4>
            <!-- <p class="card-title-desc">
              You can change your display picture from here.
            </p> -->
            <form class="needs-validation" @submit.prevent="changePhoto">
              <div class="row">
                <div class="col-md-4">
                  <img :src="profilePhoto" alt="Profile Picture" style="width: 170px; height: 170px; object-fit: cover;">
                </div>
                <div class="col-md-5 my-auto">
                  <div class="form-group position-relative">
                    <label for="validationTooltip9"
                      >Change Profile Picture</label
                    >
                    <input
                      id="validationTooltip09"
                      ref="photo"
                      type="file"
                      class="form-control"
                      accept="image/*"
                      @change="attachPhoto"
                      :class="{
                        'is-invalid':
                          (submitPhotoForm && $v.photoForm.photo.$error) ||
                          errors.photo,
                      }"
                    />
                    <div
                      v-if="
                        (submitPhotoForm && $v.photoForm.photo.$error) ||
                        errors.photo
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="errors.photo"
                        >{{ errors.photo[0] }}. &nbsp;</span
                      >
                      <span v-if="!$v.photoForm.photo.required"
                        >This field is required</span
                      >
                    </div>
                  </div>
                  <button class="btn btn-primary" type="submit">
                    Change Photo
                    <b-spinner
                      v-show="loadingPhoto"
                      small
                      class="align-middle"
                    ></b-spinner>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>

      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>